@import "../../../common/styles/variables";

.nav {
  display: flex;
  justify-content: space-between;
  gap: 25px;

  .link {
    font-size: 16px;
    font-weight: 600;
    text-transform: uppercase;
    user-select: none;
    cursor: pointer;
    color: var(#{--textDefault});
    text-decoration: none;
    position: relative;
    transition: 0.5s;

    &::after {
      opacity: 0;
      content: "";
      position: absolute;
      left: 50%;
      transform: translateX(-50%) scaleX(0);
      bottom: -6px;
      width: 90%;
      border-bottom: 3px solid #f5df4f;
      transition: 0.2s ease-in-out;
    }

    &:hover::after {
      transform: translateX(-50%) scaleX(1);
      opacity: 1;
    }
  }

  .active {
    font-size: 16px;
    font-weight: 600;
    text-transform: uppercase;
    user-select: none;
    cursor: pointer;
    color: var(#{--textDefault});
    text-decoration: none;
    position: relative;

    &::after {
      content: "";
      position: absolute;
      left: 50%;
      transform: translateX(-50%);
      bottom: -6px;
      width: 90%;
      border-bottom: 3px solid #f5df4f;
      transition: 0.2s ease-in-out;
    }
  }
}

.navMobile {
  background-color: var(#{--bgPrimaryDefault});
  color: var(#{--textDefault});
  display: flex;
  flex-direction: column;
  gap: 5px;

  .linkMobile {
    display: flex;
    font-size: 16px;
    font-weight: 600;
    text-transform: uppercase;
    user-select: none;
    cursor: pointer;
    color: var(#{--textDefault});
    text-decoration: none;
    padding: 8px 0;
    position: relative;

    &::after {
      content: "";
      position: absolute;
      left: 50%;
      transform: translateX(-50%);
      bottom: -3px;
      width: 100%;
      border-bottom: 1px solid rgba(0, 0, 0, 0.1);
      transition: 0.2s ease-in-out;
    }
  }

  .activeMobile {
    display: flex;
    font-size: 16px;
    text-transform: uppercase;
    user-select: none;
    cursor: pointer;
    color: #f5df4f;
    text-decoration: none;
    padding: 8px 0;
    position: relative;
    font-weight: 600;

    &::after {
      content: "";
      position: absolute;
      left: 50%;
      transform: translateX(-50%);
      bottom: -3px;
      width: 100%;
      border-bottom: 1px solid rgba(0, 0, 0, 0.1);
      transition: 0.2s ease-in-out;
    }
  }
}
