@import "../../../common/styles/variables";

.socialIcons {
  display: flex;
  align-items: center;
  gap: 15px;

  .iconLink {
    height: 20px;

    .cd,
    .tg,
    .git,
    .link {
      font-size: 20px;
      color: var(#{--iconsDefault});
      transition: 0.2s ease-in-out;

      &:hover {
        transform: scale(1.2);
      }
    }

    .cd:hover {
      color: #bb432c;
    }

    .tg:hover {
      color: #0088cc;
    }

    .git:hover {
      color: var(#{--textDefault});
    }

    .link:hover {
      color: #0077b5;
    }
  }

  @media (max-width: 991px) {
    gap: 12px;
  }
}
