@import "../../common/styles/variables";
@import "../../common/styles/mixins";

.aboutMeBlock {
  background-color: var(#{--bgSecondaryDefault});
  color: var(#{--textDefault});

  .wrapper {
    min-height: calc(100vh - 70px);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 100px 0;

    .title {
      display: flex;
      flex-direction: column;
      align-items: center;
      text-align: center;

      h3 {
        background-color: #f5df4f;
        font-weight: 400;
        color: #000000;
        padding: 0 8px;
        font-size: 15px;
      }
    }

    .infoBlock {
      display: grid;
      grid-template-columns: 3fr 1.5fr;
      gap: 100px;

      .nameWrapper {
        max-width: 100%;

        p {
          font-size: 21px;
        }

        .nameUnderlineWrapper {
          font-size: 32px;

          .name {
            white-space: nowrap;
          }

          .nameUnderline {
            margin-left: 8px;
            font-weight: 600;
            border-bottom: 3px solid $primaryColor;
            transition: 0.2s ease-in-out;
          }

          @media screen and (max-width: 991px) {
            text-align: center;
            font-size: 28px;
          }
        }

        @media screen and (max-width: 991px) {
          display: flex;
          flex-direction: column;
          justify-content: center;

          p {
            font-size: 19px;
          }
        }
      }

      .numbers {
        display: flex;
        justify-content: center;
        flex-direction: column;
        align-items: center;
        position: relative;

        .number {
          font-size: 150px;
          z-index: 1;
          @include adapt-font(150, 130, 180, 180);

          @media screen and (max-width: 991px) {
            transform: translate(0, 30%);
          }
        }

        .circle {
          position: absolute;
          left: 50%;
          top: 38%;
          transform: translate(-50%, -50%);
          width: 120px;
          height: 120px;
          border-radius: 50%;
          background-color: $primaryColor;
          display: inline-block;
          margin-right: 10px;

          @media screen and (max-width: 991px) {
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
          }
        }

        .numberText {
          font-size: 28px;
          text-align: center;

          @media screen and (max-width: 991px) {
            margin-top: 20px;
          }
        }

        @media screen and (max-width: 991px) {
          margin-top: -30px;
        }
      }

      p {
        margin-top: 16px;
        font-size: 21px;
        line-height: 1.8;

        @media screen and (max-width: 991px) {
          font-size: 18px;
          text-align: center;
        }
      }

      @media screen and (max-width: 991px) {
        grid-template-columns: 1fr;
        gap: 0;
      }
    }

    @media screen and (max-width: 575px) {
      padding: 0;
    }
  }

  @media screen and (max-width: 575px) {
    padding: 50px 0;
  }
}
