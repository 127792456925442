@import "../../../common/styles/variables";

.nameWrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;

  .skillName {
    margin: 10px 0;
    font-size: 16px;
    font-weight: 500;
  }

  .skillPercentage {
    font-size: 16px;
    font-weight: 500;
  }
}

.line {
  width: 100%;
  height: 8px;
  background-color: var(#{--boxShadowDefault});
  border-radius: 3px;
  overflow: hidden;
}

.filledLine {
  height: 100%;
  background-color: $primaryColor;
}
