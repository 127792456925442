@import "../../common/styles/variables";

.skillsBlock {
  color: var(#{--textDefault});
  background-color: var(#{--bgPrimaryDefault});

  .wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 100px 0;

    .title {
      display: flex;
      flex-direction: column;
      align-items: center;
      text-align: center;

      h3 {
        padding: 0 8px;
        color: #000000;
        font-weight: 400;
        font-size: 15px;
        background-color: #f5df4f;
      }
    }

    .skills {
      .static {
        display: grid;
        grid-template-columns: repeat(4, 1fr);
        gap: 16px;
      }

      .other {
        display: grid;
        grid-template-columns: repeat(4, 1fr);
        gap: 16px;
        min-height: 0;
        max-height: 0;
        overflow: hidden;
        transition: max-height 0.3s ease-in-out;

        &.visible {
          min-height: 0;
          max-height: 1500px;
          transition: max-height 1s ease-in-out;
        }
      }


      @media screen and (max-width: 991px) {
        width: 100%;

        .static {
          grid-template-columns: repeat(3, 1fr);
        }

        .other {
          grid-template-columns: repeat(3, 1fr);

          &.visible {
            min-height: 0px;
            max-height: 1000px;
          }
        }
      }

      @media screen and (max-width: 425px) {
        .static {
          column-gap: 6px;
          row-gap: 10px;
        }

        .other {
          column-gap: 6px;
          row-gap: 10px;
        }
      }
    }
  }

  .moreIcon {
    width: 60px;
    margin-top: 35px;
    color: var(#{--textDefault});
    font-size: 20px;
    background-color: var(#{--bgPrimaryDefault});
    border: 1px solid var(#{--textDefault});
    border-radius: 6px;
    cursor: pointer;
    transition: 0.1s;
    user-select: none;

    @media (min-width: 768px) {
      transition: 0.3s;

      &:hover {
        color: var(#{--bgPrimaryDefault});
        background-color: var(#{--textDefault});
        transform: scale(116%);
      }
    }

    @media screen and (max-width: 767px) {
      width: 70px;
      height: 28px;
      margin-top: 25px;

      &:active {
        color: var(#{--bgPrimaryDefault});
        background-color: var(#{--textDefault});
        transform: scale(102%);
      }
    }
  }

  @media screen and (max-width: 575px) {
    .wrapper {
      padding: 50px 0;

      .title h2 {
        font-size: 26px;
      }
    }
  }
}
