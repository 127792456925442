$textColor: #212529;
$primaryColor: #f5df4e;

:root {
  --backgroundBefore: #f5df50;
  --backgroundAfter: #212529;
}

$light-theme: (
        bgPrimaryDefault: #ffffff,
        bgSecondaryDefault: #f8f9fa,
        textDefault: #212529,
        textSecondaryDefault: rgba(0, 0, 0, 0.5),
        boxShadowDefault: #e7e7e7,
        boxShadowSecondaryDefault: rgba(0, 0, 0, .15),
        boxShadowTertiaryDefault: rgba(0, 0, 0, .25),
        iconsDefault: #4d555a,
        buttonsDefault: #212529,
        scrollbarMainDefault: #c1c1c1,
        scrollTopDefault: rgba(0, 0, 0, .2)
);

$dark-theme: (
        bgPrimaryDefault: #212529,
        bgSecondaryDefault: #1d2123,
        textDefault: #ffffff,
        textSecondaryDefault: #a49f9f,
        boxShadowDefault: #3d3a3a,
        boxShadowSecondaryDefault: rgba(147, 144, 144, 0.30),
        boxShadowTertiaryDefault: rgba(147, 144, 144, 0.50),
        iconsDefault: #ffffff,
        buttonsDefault: #f5df50,
        scrollbarMainDefault: #f5df50,
        scrollTopDefault: rgba(142, 140, 114, 0.2)
);

@each $name, $color in $light-theme {
  :root {
    --#{$name}: #{$color};
  }
}

@each $name, $color in $dark-theme {
  :root[data-theme="dark"] {
    --#{$name}: #{$color};
  }
}

@mixin set-theme-variables($theme) {
  @each $name, $color in $theme {
    --#{$name}: #{$color};
  }
}

:root {
  @include set-theme-variables($light-theme);
}

:root[data-theme="dark"] {
  @include set-theme-variables($dark-theme);
}
