@import "../../../common/styles/variables";

.contactsBlock {
  padding: 100px 0;
  overflow: hidden;
  color: $textColor;
  background-color: $primaryColor;

  & ::selection {
    color: #f5df50;
    background-color: #000000;
  }

  .wrapper {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 50px;
  }

  .getInTouch {
    h2 {
      margin: 0 0 30px;
      font-weight: 600;
      font-size: 40px;
    }

    h3 {
      margin: 0 0 8px;
      font-weight: 600;
      font-size: 21px;
    }

    .mailLink {
      position: relative;
      color: var(--textLight);
      font-size: 18px;
      text-decoration: none;

      &:after {
        position: absolute;
        top: 24px;
        left: 0;
        width: 100%;
        height: 1px;
        background: var(--textLight);
        opacity: 0;
        transition: 0.2s;
        content: "";
      }

      &:hover {
        &:after {
          opacity: 1;
        }
      }
    }

    p {
      margin: 0 0 24px;
      font-size: 21px;
      line-height: 1.8;
    }

    .icons {
      padding-top: 10px;
    }
  }

  .form {
    margin-right: 20px;

    h2 {
      margin-left: -2px;
      padding-bottom: 30px;
      font-weight: 600;
      font-size: 40px;
    }

    form {
      display: flex;
      flex-direction: column;
      font-size: 18px;

      label {
        margin-bottom: 0.5rem;
      }

      input,
      textarea {
        margin-bottom: 1rem;
        padding: 0.5rem 0;
        font-size: 18px;
        font-family: "Poppins-Regular", "Golos_Text", sans-serif;
        background-color: transparent;
        border: none;
        border-bottom: 2px solid #d7c446;
        transition: 0.3s ease-in-out;
        resize: none;

        &:focus {
          border-color: black;
          outline: none;
        }

        &:-webkit-autofill {
          -webkit-box-shadow: 0 0 0 1000px $primaryColor inset;
        }
      }

      textarea {
        height: 150px;
        resize: none;
      }

      .error {
        border-color: rgba(255, 0, 0, 0.5);
      }

      .submitBlock {
        display: flex;
        justify-content: space-between;
        min-height: 52px;

        .buttonWrapper {
          width: 190px;
        }

        .info {
          font-size: 14px;
          text-align: right;

          .infoMessage {
            display: flex;
            align-items: center;
            height: 100%;
            color: $textColor;
          }

          .errorMessage {
            color: rgba(255, 0, 0, 0.5);
          }
        }
      }
    }
  }

  @media screen and (max-width: 768px) {
    .form {
      form {
        input,
        textarea {
          width: 100%;
        }

        .submitBlock {
          flex-direction: column;
          gap: 10px;

          .buttonWrapper {
            width: 100%;
          }

          .info {
            text-align: center;

            .infoMessage {
              display: none;
            }
          }
        }
      }
    }
  }

  @media screen and (max-width: 575px) {
    padding: 50px 0;
    background-color: #f5df4f;

    .wrapper {
      grid-template-columns: repeat(1, 1fr);
    }

    .getInTouch {
      h2 {
        font-size: 30px;
      }

      p {
        font-size: 16px;
      }

      .mailLink {
        font-size: 16px;
      }
    }

    .form {
      margin-right: 0;

      h2 {
        font-size: 30px;
      }

      form {
        font-size: 16px;

        input,
        textarea {
          font-size: 18px;
        }
      }
    }
  }

  & ::-webkit-scrollbar-thumb {
    background-color: #f5df50;
    border: 2px solid #2a2828;
    border-radius: 5px;

    &:hover {
      background-color: #f5df50;
      border: none;
    }

    &:active {
      background-color: #f5df50;
    }
  }

  & ::-webkit-scrollbar-track {
    background-color: #2a2828;
    border-radius: 5px;
  }
}

.loadingWrapper {
  position: absolute;
  top: -16px;
  right: -9px;
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 105%;
  height: 105%;
  background-color: rgba(255, 255, 255, 0.5);
  border-radius: 10px;

  .loading {
    width: 80px;
    height: 80px;
    border: 3.5px solid #f3f3f3;
    border-top: 3.5px solid $textColor;
    border-radius: 50%;
    animation: spin 1.3s linear infinite;
  }

  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }

    100% {
      transform: rotate(360deg);
    }
  }
}
