.container {
  width: 100%;
  max-width: 1370px;
  height: 100%;
  margin: 0 auto;
  padding: 0 15px;
  transition: 0.3s ease-in-out;
}

@media screen and (max-width: 1399px) {
  .container {
    max-width: 1140px;
  }
}

@media screen and (max-width: 1199px) {
  .container {
    max-width: 960px;
  }
}
