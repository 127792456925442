@import "../../styles/variables";

.container {
  position: fixed;
  right: 16px;
  bottom: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 36px;
  height: 36px;
  color: #fff;
  font-size: 16px;
  line-height: 36px;
  text-align: center;
  background-color: var(#{--scrollTopDefault});
  border-radius: 50px;
  box-shadow: 0 5px 15px var(#{--boxShadowSecondaryDefault});
  cursor: pointer;
  opacity: 0;
  transition: .1s;

  @media (min-width: 768px) {
    transition: .3s;

    &:hover {
      color: #000;
      background-color: #f5df4e;
      box-shadow: 0 5px 15px var(#{--boxShadowTertiaryDefault});
    }
  }

  @media (max-width: 767px) {
    transition: 0.3s;

    &:active {
      color: #000;
      background-color: #f5df4e;
      box-shadow: 0 5px 15px rgba(0, 0, 0, .25);
      transform: scale(1.1);
      transition: 0.1s;
    }
  }
}

.visible {
  opacity: 1;
}
