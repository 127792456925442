.header {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  color: var(#{--textDefault});
  margin: 15px 0 48px;
  font-size: 40px;
  font-weight: 600;
  transition: 0.3s;

  @media screen and (max-width: 575px) {
    font-size: 32px;
    margin: 15px 0 30px;
  }
}
