@import "../../common/styles/variables";

.resumeBlock {
  background-color: var(#{--bgSecondaryDefault});
  color: var(#{--textDefault});

  .wrapper {
    height: 100%;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: flex-start;
    padding: 100px 0;

    .title {
      display: flex;
      flex-direction: column;
      align-items: center;
      text-align: center;

      h3 {
        background-color: #f5df4f;
        color: #000000;
        padding: 0 8px;
        font-weight: 400;
        font-size: 15px;
      }
    }

    .resumes {
      margin-top: 48px;
      width: 100%;
      display: grid;
      grid-template-columns: 1fr 1fr;
      gap: 20px;

      h2 {
        font-weight: 600;
        font-size: 28px;
        margin-bottom: 32px;
      }

      .education,
      .experience {
        flex-basis: 50%;

        .educationInfo,
        .experienceInfo {
          padding: 0 0 0 16px;
          border-left: 2px solid $primaryColor;

          h3 {
            font-weight: 500;
            font-size: 21px;
          }

          h4 {
            margin-top: 8px;
            font-size: 16px;
            font-weight: 400;
            line-height: 1.4;
          }

          p {
            margin-top: 8px;
            font-size: 16px;
            line-height: 29px;
            color: var(#{--textSecondaryDefault});
            min-height: 90px;
          }

          hr {
            opacity: 0.1;
            margin-bottom: 1.5rem !important;
            margin-top: 1.5rem !important;
          }
        }
      }

      @media screen and (max-width: 992px) {
        margin-top: 0;
        padding: 0 15px 15px;
        grid-template-columns: 1fr;
      }
    }

    .buttonWrapper {
      margin-top: 72px;
      width: 230px;

      @media screen and (max-width: 575px) {
        margin-top: 50px;
      }
    }

    @media screen and (max-width: 575px) {
      padding: 50px 0 50px;
    }
  }
}
