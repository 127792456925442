@import "../../../common/styles/variables";

.btn {
  width: 100%;
  height: 100%;
  padding: 0.75rem 1rem;
  color: white;
  font-weight: 500;
  font-size: 16px;
  font-family: "Poppins-Regular", "Golos_Text", sans-serif;
  background-color: #212529;
  border: 1px solid #f5df50;
  border-radius: 3px;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.15);
  cursor: pointer;
  transition: 0.1s;
  user-select: none;

  @media (min-width: 768px) {
    transition: 0.2s;

    &:hover {
      background-color: #424649;
    }

    &:disabled:hover {
      background-color: #212529;
    }
  }

  &:disabled {
    cursor: default;
    opacity: 0.7;
  }

  &:disabled:active {
    color: white;
    background-color: #212529;
    border: 1px solid #f5df50;
  }

  &:active {
    color: #000000;
    background-color: #f5df50;
    border: 1px solid #000000;
  }

  &.secondary {
    color: var(#{--textDefault});
    background-color: var(#{--bgPrimaryDefault});
    border: 2px solid var(#{--textDefault});
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.15);

    @media (min-width: 768px) {
      transition: 0.2s;

      &:hover {
        color: var(#{--bgPrimaryDefault});
        background-color: var(#{--textDefault});
      }

      &:disabled:hover {
        background-color: #212529;
      }
    }

    &:active {
      padding: 0.75rem 1rem;
      color: #000000;
      background-color: #f5df50;
      border: 2px solid #000000;
      transform: scale(102%);
    }
  }
}
