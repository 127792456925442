.skill {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 260px;
  padding: 20px;
  border-radius: 5px;
  transition: 0.5s;

  &:hover {
    background-color: #f5df50;

    .icon {
      color: white;
      transform: scale(1.1);
    }
  }

  .icon {
    color: #f5df50;
    font-size: 70px;
    transition: 0.5s;
  }

  h3 {
    margin-top: 20px;
    font-weight: 600;
    font-size: 22px;
    text-align: center;
    cursor: default;
  }

  p {
    line-height: 1.4;
    text-align: justify;
    cursor: default;
  }

  @media screen and (max-width: 1096px) {
    width: 230px;
  }

  @media screen and (max-width: 991px) {
    width: 100%;
  }

  @media screen and (max-width: 575px) {
    padding: 10px;

    .icon {
      font-size: 50px;
    }

    h3 {
      font-size: 16px;
    }

    &:hover {
      background-color: transparent;

      .icon {
        color: #f5df50;
        transform: scale(1);
      }
    }
  }

  @media screen and (max-width: 360px) {
    padding: 10px 2px;
  }
}
