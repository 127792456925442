@import "../../common/styles/variables";

.footer {
  overflow: hidden;
  background-color: #212529;

  .wrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 70px;
    color: #ffffff;
    text-align: center;

    .status {
      display: block;
      cursor: pointer;
      transition: color 0.2s ease;

      &:hover {
        color: #f5df4f;
      }
    }
  }

  .copyright {
    a {
      color: #f5df4f;
      text-decoration: underline;

      &:hover {
        text-decoration: none;
      }
    }
  }

  @media screen and (max-width: 575px) {
    .wrapper {
      flex-direction: column;
      gap: 10px;
      justify-content: center;
    }
  }
}
