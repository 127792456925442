@import "../common/styles/variables";

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

body::before, body::after {
  position: fixed;
  right: 0;
  left: 0;
  z-index: -1;
  display: block;
  width: 100%;
  content: '';
}

body::before {
  top: 0;
  height: 50%;
  background-color: var(#{--backgroundBefore});
}

body::after {
  bottom: 0;
  height: 50%;
  background-color: var(#{--backgroundAfter});
}

body {
  font-weight: 400;
  font-family: -apple-system, BlinkMacSystemFont, "Poppins", "Golos_Text", "Segoe UI", "Roboto", "Oxygen", "Ubuntu",
  "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  line-height: 1.2;
  letter-spacing: -0.02em;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

::selection {
  color: #ffffff;
  background-color: #f5df50;
}

::-webkit-scrollbar {
  width: 10px;
}

::-webkit-scrollbar-thumb {
  background-color: var(#{--scrollbarMainDefault});
  border: 2px solid var(#{--bgSecondaryDefault});
  border-radius: 5px;
}

::-webkit-scrollbar-thumb:hover {
  background-color: var(#{--scrollbarMainDefault});
  border: none;
}

::-webkit-scrollbar-track {
  background-color: var(#{--bgPrimaryDefault});
}
