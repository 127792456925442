@import "../../styles/variables";

.wrapper {
  position: fixed;
  bottom: 66px;
  left: 50%;
  z-index: -1;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 25%;
  overflow: hidden;
  color: #ffffff;
  text-overflow: ellipsis;
  background-color: rgba(0, 0, 0, 0.8);
  border-radius: 4px;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.15);
  transform: translate(-50%, -100%);
  opacity: 0;
  transition: all 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;

  &.show {
    z-index: 1;
    transform: translate(-50%, 0);
    opacity: 1;
  }

  .body {
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 100%;
    max-height: 200px;
    padding: 10px 16px;

    .success {
      color: #07bc0c;
    }

    .error {
      color: #dc3545;
    }
  }

  @media screen and (max-width: 767px) {
    width: 50%;
  }

  @media screen and (max-width: 575px) {
    width: calc(100% - 30px);
  }

  .btn {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: 10px;
    border: none;
    cursor: pointer;

    svg {
      font-size: 26px;
      transition: 0.2s;
      fill: $primaryColor;

      @media (min-width: 768px) {
        transition: 0.2s;

        &:hover {
          transform: scale(1.1);
        }
      }

      &:active {
        transform: scale(1.1);
      }
    }
  }

  .progressBar {
    width: 100%;
    height: 4px;
    background-color: $primaryColor;
    transition: width 0.1s linear;
  }
}
