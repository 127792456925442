@import "../../../common/styles/variables";

.wrapper {
  position: fixed;
  top: 0;
  left: 0;
  z-index: -1;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.1);
  opacity: 0;
  backdrop-filter: blur(0.5px);
  transition: 0.3s;

  .modal {
    position: relative;
    z-index: 2;
    width: 768px;
    max-height: 80%;
    margin: 50px auto;
    padding: 16px;
    overflow-y: auto;
    color: var(#{--textDefault});
    background: var(#{--bgPrimaryDefault});
    border-radius: 5px;
    box-shadow: 0 5px 15px rgb(0 0 0 / 50%);

    .header {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-bottom: 16px;
      padding-bottom: 10px;
      font-weight: 500;
      font-size: 21px;
      border-bottom: 1px solid var(--boxShadowDefault);

      .icon {
        font-size: 26px;
        cursor: pointer;
        transition: .2s;

        &:active {
          transform: scale(1.2);
        }

        @media (min-width: 768px) {
          &:hover {
            color: #f5df50;
            transform: scale(1.2);
          }
        }
      }
    }
  }

  &.open {
    z-index: 99999;
    opacity: 1;
  }

  @media screen and (max-width: 768px) {
    .modal {
      width: 100%;
      margin: auto 0 0 0;
      padding: 12px 10px;
      border-radius: 4px 4px 0 0;

      .header {
        margin-bottom: 12px;
        padding-bottom: 10px;
      }
    }
  }
}
