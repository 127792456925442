@import "../../common/styles/variables";

.header {
  position: fixed;
  z-index: 2;
  width: 100%;
  color: var(#{--textDefault});
  text-align: center;
  background-color: var(#{--bgPrimaryDefault});
  box-shadow: 0 -6px 10px 5px var(#{--boxShadowDefault});

  .wrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 70px;
  }

  .logo {
    font-weight: 700;
    font-size: 30px;
    font-family: "Poppins", "Golos_Text", sans-serif;
    text-transform: uppercase;
    cursor: pointer;
    user-select: none;

    a {
      color: var(#{--textDefault});
      text-decoration: none;
    }
  }

  .icons {
    display: flex;
    gap: 15px;
    align-items: center;

    .mobileMenuButton {
      display: none;
    }
  }

  .mobileMenu {
    position: absolute;
    top: 59px;
    right: 0;
    left: 0;
    z-index: 1;
    width: 100%;
    padding: 15px;
    background-color: var(#{--bgPrimaryDefault});
    box-shadow: 0 2px 10px 1px var(#{--boxShadowDefault});
  }

  @media (max-width: 991px) {
    &.scrollDown {
      opacity: 0;
      animation: fadeToTop 0.2s ease-in-out;
      pointer-events: none;
    }

    &.scrollUp {
      opacity: 1;
      animation: fadeInFromTop 0.2s ease-in-out;
    }

    .wrapper {
      height: 60px;
    }

    .mainMenu {
      display: none;
    }

    .icons {
      gap: 12px;

      .mobileMenuButton {
        display: block;
        height: 20px;
        transform: translate(0, -2px);
      }
    }
  }

  @media (max-width: 575px) {
    .mobileMenu {
      width: 100%;
    }
  }
}

@keyframes fadeInFromTop {
  0% {
    transform: translateY(-100%);
    opacity: 0;
  }

  100% {
    transform: translateY(0);
    opacity: 1;
  }
}

@keyframes fadeToTop {
  0% {
    transform: translateY(0);
    opacity: 1;
  }

  100% {
    transform: translateY(-100%);
    opacity: 0;
  }
}
