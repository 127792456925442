@import "../../../common/styles/variables";

.customSelect {
  position: relative;
  z-index: 1;
  width: 40px;

  .selectedValue {
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 22px;
    padding-left: 4px;
    color: var(--bgPrimaryDefault);
    text-transform: capitalize;
    background-color: var(--iconsDefault);
    border-radius: 3px;
    cursor: pointer;
    transition: background-color 0.3s;
  }

  .arrow {
    width: 0;
    height: 0;
    margin-right: 4px;
    border-top: 4px solid var(--bgPrimaryDefault);
    border-right: 4px solid transparent;
    border-left: 4px solid transparent;
    transition: transform 0.3s;
  }

  .options {
    position: absolute;
    top: 100%;
    left: 0;
    display: none;
    overflow: hidden;
    color: var(--textDefault);
    background-color: var(--bgPrimaryDefault);
    border-radius: 4px;
    box-shadow: 0 1px 10px 4px var(--boxShadowSecondaryDefault);
    transform-origin: top;
    opacity: 0;
    transition: opacity 0.3s, transform 0.3s;

    > div {
      padding: 9px;
      cursor: pointer;
      transition: 0.3s background, 0.3s color;

      a {
        color: inherit;
        font-weight: 500;
        font-size: 17px;
        text-transform: capitalize;
        text-decoration: none;
        outline-color: transparent;
      }

      &:hover {
        color: $textColor;
        background: $primaryColor;
      }
    }
  }

  &.open .arrow {
    transform: rotate(180deg);
  }

  &.open .options {
    left: 50%;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    transform: translateX(-50%);
    opacity: 1;
  }
}
