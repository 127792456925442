@import "../../../../../common/styles/variables";

.footerModal {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 352px;

  .loading {
    width: 60px;
    height: 60px;
    border: 3px solid var(--boxShadowDefault);
    border-top: 3px solid var(--textDefault);
    border-radius: 50%;
    animation: spin 1.3s linear infinite;
  }

  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }

    100% {
      transform: rotate(360deg);
    }
  }
}