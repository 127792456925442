.burger-button {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  width: 24px;
  height: 24px;
  background-color: transparent;
  border: none;
  cursor: pointer;
  padding: 0;
  box-sizing: border-box;

  .burger-line {
    width: 100%;
    height: 2px;
    background-color: var(#{--iconsDefault});
    border-radius: 10px;
    transition: all 0.2s ease-out;
  }

  &.open {
    .burger-line:nth-child(1) {
      transform: rotate(45deg) translate(5px, 6px);
    }

    .burger-line:nth-child(2) {
      opacity: 0;
    }

    .burger-line:nth-child(3) {
      transform: rotate(-45deg) translate(5px, -6px);
    }
  }
}
