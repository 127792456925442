.skillLinesWrapper {
  margin-top: 26px;
  width: 100%;

  @media (max-width: 767px) {
    margin-top: 0;
  }

  h2 {
    font-weight: 600;
    font-size: 28px;
  }

  .skillLinesBlock {
    display: grid;
    grid-template-columns: 1fr 1fr;
    margin: 25px auto 0;
    column-gap: 50px;
    row-gap: 20px;

    @media (max-width: 767px) {
      grid-template-columns: 1fr;
    }
  }
}
