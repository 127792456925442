.socialIcons {
  display: flex;
  align-items: center;

  .iconLink {
    margin-right: 20px;
  }

  .cd,
  .tg,
  .git,
  .link {
    font-size: 26px;
    color: #4d555a;
    transition: 0.2s ease-in-out;

    &:hover {
      transform: scale(1.2);
    }
  }

  .cd:hover {
    color: #bb432c;
  }

  .tg:hover {
    color: #0088cc;
  }

  .git:hover {
    color: #000000;
  }

  .link:hover {
    color: #0077b5;
  }
}
